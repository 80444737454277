<template>
  <input
    :class="className"
    accept="image/x-png,image/gif,image/jpeg"
    type="file"
    @change="handleFileInput"
  />
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleFileInput(_event) {
      this.$emit("fileInput", _event);
    },
  },
};
</script>
