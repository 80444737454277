<template>
  <div-wrapper>
    <div-wrapper id="scan-overlay" className="modal-overlaybg open">
    </div-wrapper>
    <div-wrapper
      id="scanning-data"
      className="modal_pageview slideup modelslideup"
    >
      <Span className="close-btn" @handleClick="$emit('closeModal')">
        <close-icon />
      </Span>
      <div-wrapper class="barmodelbox scan-msgbox">
        <div-wrapper class="incontent">
          <div-wrapper class="title-block">
            <h2>{{ $t("_raiseDispute") }}</h2>
          </div-wrapper>
          <div-wrapper class="bar-mid">
            <div-wrapper class="field-row">
              <label class="text-label">{{ $t("_category") }}</label>
              <div-wrapper class="rel-box">
                <select class="select-opt" v-model="catSelected">
                  <option disabled value>{{ $t("_select") }}</option>
                  <option
                    v-for="(reason, index) in Object.keys(disputReasons)"
                    :key="index"
                  >
                    {{ reason }}
                  </option>
                </select>
                <span class="drop-icon">
                  <img src="../../assets/images/select-drop-btm.svg" alt />
                </span>
              </div-wrapper>
            </div-wrapper>
            <div-wrapper class="field-row">
              <label class="text-label">{{ $t("_subCategory") }}</label>
              <div-wrapper class="rel-box">
                <select class="select-opt" v-model="subCatSelected">
                  <option disabled value>{{ $t("_select") }}</option>
                  <option
                    v-for="(reason, index) in disputReasons[catSelected]"
                    :key="index"
                  >
                    {{ reason }}
                  </option>
                </select>
                <span class="drop-icon">
                  <img src="../../assets/images/select-drop-btm.svg" alt />
                </span>
              </div-wrapper>
            </div-wrapper>
            <div-wrapper class="field-row">
              <label class="text-label">{{ $t("_message") }}</label>
              <Textarea
                name="reasonTxt"
                class="textare-style"
                :placeholder="$t('_typeYourMsg')"
                @textarea="getReason"
              />
            </div-wrapper>
            <div-wrapper class="field-row">
              <label class="text-label">Upload Proof</label>
              <div-wrapper className="upload-item">
                <div-wrapper className="upload-file">
                  <div-wrapper className="upload-icon">
                    <camera-icon />
                  </div-wrapper>
                  <p>{{ $t("_takeFurtherPhotos") }}</p>
                </div-wrapper>
                <FileInput
                  name="disputeProof"
                  className="inputfile"
                  @fileInput="getFile"
                />
              </div-wrapper>
            </div-wrapper>
            <div-wrapper v-if="disputeProof" class="attachment-data">
              <img :src="disputeProof" />
            </div-wrapper>
          </div-wrapper>
          <div-wrapper class="bar-bottom">
            <button class="button" @click="submitDispute">
              {{ $t("_submit") }}
            </button>
          </div-wrapper>
        </div-wrapper>
      </div-wrapper>
    </div-wrapper>
    <Popup :message="message" @handleClick="message = ''" />
    <Loader v-if="isLoading" />
  </div-wrapper>
</template>
<script>
import { POST } from "../../utils/HttpStatusCodeConst";
import { uploadImageFile } from "../../utils/Helper";
import ApiRequest from "../../services/ApiRequest";
import FileInput from "../form/FileInput";
import DivWrapper from "@/components/form/DivWrapper";
import CameraIcon from "@/components/svgIcons/CameraIcon";
import CloseIcon from "@/components/svgIcons/CloseIcon";
import Textarea from "@/components/form/TextAreaWrapper";
import Loader from "@/components/commonUI/LoaderWrapper";
import Span from "@/components/other/Span";
import { BASE_API } from '../../utils/constants';
// import {DISPUTE_SUB_CATEGORIES} from "@/utils/constants";

export default {
  components: {
    Popup: () => import("@/components/Popup"),
    DivWrapper,
    CameraIcon,
    FileInput,
    CloseIcon,
    Textarea,
    Loader,
    Span
  },
  props:{
    order: Object
  },
  data() {
    return {
      disputReasons: this.$t("_disputeReasons"),
      subCatSelected: "",
      isLoading: false,
      disputeProof: "",
      catSelected: "",
      reasonTxt: "",
      message: "",
      subCategoryId:null
    };
  },
  // created(){
  //   let subCategoryData = {
  //     InComingOrders:"incomingOrders",
  //     ToBeDeliveredOrders:"deliveryOrders",
  //     ScanOrders:"newOrders",
  //     ReturnOrders:"returnOrders",
  //     RedispatchOrders:"redispatchOrders",
  //     MissingLostorders:"lostOrders",
  //     DeliveredOrders:"CODOrders",
  //   }
  //   if(subCategoryData.hasOwnProperty(this.$route.name))
  //     this.subCategoryId =  DISPUTE_SUB_CATEGORIES[subCategoryData[this.$route.name]]
  //   else
  //     this.subCategoryId =   DISPUTE_SUB_CATEGORIES['others']
  // },
  methods: {
    getFile(_event) {
      if (
        _event.target.files[0].type === "image/png" ||
        _event.target.files[0].type === "image/jpeg"
      ) {
        this.isLoading = !this.isLoading;
        uploadImageFile(_event.target.files[0])
          .then((result) => {
            setTimeout(() => {
              this[_event.target.name] = result;
              this.isLoading = !this.isLoading;
            }, 2000);
          })
          .catch((err) => {
            this.isLoading = !this.isLoading;
            alert(err);
          });
      }
    },

    getReason(_event) {
      this[_event.target.name] = _event.target.value;
    },

    submitDispute() {
      if (!this.catSelected || !this.subCatSelected || !this.reasonTxt) {
        this.$swal({
          text: "Please select category & sub category and write the reason",
          icon: "info"
        });
        return;
      }
      this.isLoading = !this.isLoading;
      ApiRequest(BASE_API, "/partner91/raise-dispute", POST, {
        orderId: this.order.orderId,
        description: `${this.catSelected} - ${this.subCatSelected}`,
        remark: this.reasonTxt + " " + this.disputeProof,
        subcategory: this.subCategoryId
      })
        .then((_data) => {
          this.isLoading = !this.isLoading;
          this.$emit("closeModal");
          alert(_data.result.Message);
        })
        .catch(() => {
          this.isLoading = !this.isLoading;
          this.$emit("closeModal");
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_modal-box.scss";
.upload-item {
  border-radius: 8px;
  border: 1px solid $brdColor;
  width: 100%;
  padding: 8px;
  background: #fff;
  position: relative;
  height: 98px;

  .upload-file {
    width: 100%;
    @include posXYcenter();
    text-align: center;

    p {
      font-size: 0.875em;
      color: $mdlightBlack;
    }
  }
  .inputfile {
    width: 100%;
    height: 100%;
    @include posXYcenter();
    opacity: 0;
  }
  .upload-icon {
    width: 100%;
    height: auto;

    svg {
      width: 37px;
      height: 30px;
      fill: $mdlightBlack;
    }
  }
}
</style>
